import React from "react";
import { motion } from "framer-motion";

export default function SplitBanner() {
  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <motion.div
        className="w-full md:w-1/2 bg-cover bg-center min-h-[50vh] md:min-h-screen"
        style={{ backgroundImage: "url('/lipstickSale.png')" }}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      />
      <motion.div
        className="w-full md:w-1/2 bg-pink-100 flex items-center justify-center p-12 min-h-[50vh] md:min-h-screen"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="text-center">
          <h2 className="text-4xl font-bold mb-4">Lip Care Essentials</h2>
          <p className="text-xl mb-6">Nourish and protect your lips</p>
          <motion.button
            className="px-6 py-3 bg-pink-500 text-white rounded-full font-semibold hover:bg-pink-600 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Learn More
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
}