import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";

export default function ParallaxSection() {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ["-50%", "0%"]);

  return (
    <div className="relative h-screen overflow-hidden">
      <motion.div
        className="absolute inset-0"
        style={{ y }}
      >
        <img
          src="/liptop6.png"
          alt="Parallax Background"
          className="w-full h-full object-cover object-center"
        />
      </motion.div>
      <div className="absolute inset-0 bg-black opacity-50" />
      <div className="relative h-full flex items-center justify-center">
        <div className="text-center text-white">
          <h2 className="text-4xl font-bold mb-4">Join Our Community</h2>
          <p className="text-xl mb-6">Get exclusive offers and beauty tips</p>
          <motion.button
            className="px-6 py-3 bg-white text-pink-500 rounded-full font-semibold hover:bg-pink-100 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Sign Up Now
          </motion.button>
        </div>
      </div>
    </div>
  );
}