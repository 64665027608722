import React from "react";
import { motion } from "framer-motion";
const floatingLips = [
  { rotate: -20, y: "14vh", x: "35vw" },
];

export default function Hero() {
  return (
    <div className="relative h-screen flex items-center justify-center overflow-hidden">
      <motion.div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: "url('/HeroImage.png')",
        }}
        initial={{ scale: 1.1 }}
        animate={{ scale: 1 }}
        transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
      />
      <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/60" />
      
      {floatingLips.map((lip, index) => (
        <motion.img
          key={index}
          src="/lips.png"
          alt="Floating lips"
          className="absolute w-24 h-auto"
          style={{
            top: lip.y,
            left: lip.x,
            rotate: `${lip.rotate}deg`,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 0.5, delay: 2 + index * 0.2 }}
        />
      ))}

      <motion.div
        className="relative text-center text-white z-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <motion.h1 
          className="text-6xl font-bold mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.7 }}
        >
          Beauty Begins with a Smile
        </motion.h1>
        <motion.p 
          className="text-xl mb-8 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.9 }}
        >
          Your number one site for all things lips. Lipstick, lip care,
          lip fillers, how to guides and more.
        </motion.p>
        <motion.button
          className="px-8 py-4 bg-pink-500 text-white rounded-full font-semibold hover:bg-pink-600 transition-colors text-lg"
          whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(236, 72, 153, 0.5)" }}
          whileTap={{ scale: 0.95 }}
        >
          Explore Now
        </motion.button>
      </motion.div>
    </div>
  );
}