import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

const cards = [
  { 
    title: "Matte Lipsticks", 
    description: "Long-lasting, bold colors",
    image: "/animationCard1.png"
  },
  { 
    title: "Lip Glosses", 
    description: "Shine and hydration in one",
    image: "/animationCard2.png"
  },
  { 
    title: "Lip Liners", 
    description: "Define and perfect your lip shape",
    image: "/animationCard1.png"
  },
];

const CardItem = ({ card, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.5 });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      className="bg-white rounded-lg shadow-md w-80 overflow-hidden"
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      whileHover={{ scale: 1.05 }}
    >
      <img 
        src={card.image} 
        alt={card.title} 
        className="w-full h-60 object-cover"
      />
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-2">{card.title}</h3>
        <p className="text-gray-600">{card.description}</p>
      </div>
    </motion.div>
  );
};

const Bubble = ({ size, position, duration }) => (
    <motion.div
      className="absolute rounded-full bg-pink-300 opacity-40"
      style={{
        width: size,
        height: size,
        ...position,
      }}
      animate={{
        y: [0, -50, 0],
        x: [0, 25, 0],
        scale: [1, 1.1, 1],
      }}
      transition={{
        duration: duration,
        repeat: Infinity,
        ease: "easeInOut",
      }}
    />
  );
  
  export default function AnimatedCards() {
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.2 });
    const controls = useAnimation();
  
    useEffect(() => {
      if (isInView) {
        controls.start({ opacity: 1, y: 0 });
      }
    }, [isInView, controls]);
  
    return (
      <div className="relative bg-gradient-to-b from-gray-50 to-gray-100 py-16 overflow-hidden">
        <Bubble size={150} position={{ top: '5%', left: '5%' }} duration={12} />
        <Bubble size={100} position={{ top: '20%', right: '10%' }} duration={15} />
        <Bubble size={120} position={{ bottom: '15%', left: '15%' }} duration={18} />
        <Bubble size={180} position={{ bottom: '5%', right: '5%' }} duration={20} />
        <Bubble size={80} position={{ top: '40%', left: '30%' }} duration={14} />
        <Bubble size={60} position={{ bottom: '30%', right: '25%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '7%', right: '20%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '94%', right: '3%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '34%', right: '23%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '55%', right: '8%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '15%', right: '18%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '25%', right: '5%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '35%', right: '3%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '45%', right: '94%' }} duration={16} />
        <Bubble size={60} position={{ bottom: '55%', right: '98%' }} duration={16} />
        
        <div className="container mx-auto relative z-10" ref={containerRef}>
          <motion.h2 
            className="text-3xl font-bold text-center mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={controls}
            transition={{ duration: 0.5 }}
          >
            Our Products
          </motion.h2>
          <div className="flex flex-wrap justify-center gap-8">
            {cards.map((card, index) => (
              <CardItem key={index} card={card} index={index} />
            ))}
          </div>
        </div>
      </div>
    );
  }