import React from "react";
import Hero from "./components/Hero";
import FullWidthBanner from "./components/FullWidthBanner";
import ImageGrid from "./components/ImageGrid";
import SplitBanner from "./components/SplitBanner";
import ImageCollage from "./components/ImageCollage";
import AnimatedCards from "./components/AnimatedCards";
import ParallaxSection from "./components/ParallaxSection";
import FeatureSlider from "./components/FeatureSlider";
import TopBanner from "./components/TopBanner";
import Footer from "./components/Footer";
import ConstructionBanner from "./components/ConstructionBanner";
import useImageLoader from "./hooks/useImageLoader";
import Loader from "./components/Loader";

const App = () => {
  const imageUrls = [
    "/HeroImage.png",
    "/lips.png",
    "/LipstickBanner.png",
  ];

  const imagesLoaded = useImageLoader(imageUrls);

  if (!imagesLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <ConstructionBanner />
      <TopBanner />
      <Hero />
      <FullWidthBanner />
      <ImageGrid />
      <FeatureSlider />
      <SplitBanner />
      <ImageCollage />
      <AnimatedCards />
      <ParallaxSection />
      <Footer />
    </div>
  );
};

export default App;
